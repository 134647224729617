import React from 'react';
import { Link } from 'react-router-dom';
import './CancellationConfirmation.css';

const CancellationConfirmation = () => {
  return (
    <div className="cancellation-container">
      <h1>Subscription Cancelled</h1>
      <p>Your subscription has been successfully cancelled.</p>
      <Link to="/membership">
        <button className="modern-button">Continue</button>
      </Link>
    </div>
  );
};

export default CancellationConfirmation;
