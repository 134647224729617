import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import './Membership.css';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { FaHome, FaPlus } from 'react-icons/fa';

const API_URL = 'https://us-central1-campuscraze-2dc3b.cloudfunctions.net/api';

const Membership = () => {
  const { currentUser, updateMembershipStatus } = useAuth();
  const navigate = useNavigate();
  const [subscriptionRenewalDate, setSubscriptionRenewalDate] = useState('Loading...');
  const [stripeCustomerId, setStripeCustomerId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      try {
        const userRef = doc(db, 'profiles', currentUser.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const stripeCustomerId = userDoc.data().stripeCustomerId;
          setStripeCustomerId(stripeCustomerId);

          const response = await fetch(`${API_URL}/retrieve-subscription?customer_id=${stripeCustomerId}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          if (data && data.data && data.data[0]) {
            const renewalDate = new Date(data.data[0].current_period_end * 1000).toLocaleDateString();
            setSubscriptionRenewalDate(renewalDate);
          } else {
            setSubscriptionRenewalDate('N/A');
          }
        } else {
          console.error('No user document found');
        }
      } catch (error) {
        console.error('Error fetching subscription details:', error);
        setSubscriptionRenewalDate('N/A');
      }
    };

    if (currentUser) {
      fetchSubscriptionDetails();
    }
  }, [currentUser]);

  const handleCheckout = async () => {
    setLoading(true); // Start loading

    try {
      const response = await fetch(`${API_URL}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: currentUser.uid }),
      });

      const session = await response.json();
      const stripe = window.Stripe('pk_live_51PdNLi2KAsh57SklhwGRt69OtCKZ6I7bwKChD9UebclGYE61GDj0y9rl4HCc5Kpvf70ozMiGzbx8AnCbPFUFqgIU00hRk43ygM');
      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (error) {
        console.error(error.message);
      }
    } catch (error) {
      console.error('Error during checkout:', error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleCancelSubscription = async () => {
    setLoading(true); // Start loading
    
    try {
      const body = JSON.stringify({ userId: currentUser.uid, stripeCustomerId });
  
      const response = await fetch(`${API_URL}/cancel-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      });
  
      const result = await response.json();
      if (result.success) {
        await updateMembershipStatus('Free');
        setSubscriptionRenewalDate('N/A');
        navigate('/cancellation-confirmation');
      } else {
        console.error('Error cancelling subscription:', result.error);
      }
    } catch (error) {
      console.error('Error cancelling subscription:', error);
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <div className="membership-container">
      {loading && <div className="loading-overlay">Loading...</div>}
      <button className="back-button-membership" onClick={() => navigate('/home')}>
        <FaArrowLeft className="button-icon" /> Back
      </button>
      <h1>Membership Status</h1>
      {currentUser ? (
        <div className="membership-status">
          <p>Current Membership: {currentUser.membership || 'Free'}</p>
          {currentUser.membership === 'Elite' ? (
            <>
              <div className="upgrade-box">
                <h2>CampusCraze Elite</h2>
                <p className="price">$2.99/Month</p>
                <ul className="perks-list">
                <li style={{ color: '#ffd700', position: 'relative', paddingLeft: '25px', marginBottom: '10px' }}>
                <span style={{ position: 'absolute', left: '0', color: '#ffd700' }}>✔</span>Unlimited DMs in CampusConnect</li>
                <li style={{ color: '#ffd700', position: 'relative', paddingLeft: '25px', marginBottom: '10px' }}>
                <span style={{ position: 'absolute', left: '0', color: '#ffd700' }}>✔</span>Your parties will be highlighted and appear on top in CampusCraze</li>
                <li style={{ color: '#ffd700', position: 'relative', paddingLeft: '25px', marginBottom: '10px' }}>
                <span style={{ position: 'absolute', left: '0', color: '#ffd700' }}>✔</span>First to access new features</li>
                </ul>
                <p>Subscription Renewal Date: {subscriptionRenewalDate}</p>
                <p className="warning-text">Warning: Cancel Subscription will cancel immediately.</p>
                <button className="modern-button" onClick={handleCancelSubscription}>
                  Cancel Subscription
                </button>
              </div>
            </>
          ) : (
            <div className="upgrade-box">
              <h2>CampusCraze Elite</h2>
              <p className="price">$2.99/Month</p>
              <ul className="perks-list">
              <li style={{ color: '#ffd700', position: 'relative', paddingLeft: '25px', marginBottom: '10px' }}>
              <span style={{ position: 'absolute', left: '0', color: '#ffd700' }}>✔</span>Unlimited DMs in CampusCrush</li>
              <li style={{ color: '#ffd700', position: 'relative', paddingLeft: '25px', marginBottom: '10px' }}>
              <span style={{ position: 'absolute', left: '0', color: '#ffd700' }}>✔</span>Promote your party in an exclusive section in CampusCraze</li>
              <li style={{ color: '#ffd700', position: 'relative', paddingLeft: '25px', marginBottom: '10px' }}>
              <span style={{ position: 'absolute', left: '0', color: '#ffd700' }}>✔</span>First to access new features</li>
              </ul>
              <button className="modern-button" onClick={handleCheckout}>
                Upgrade Now!
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="not-logged-in">
          <p>Please log in to view your updated membership status.</p>
          <Link to="/">
            <button className="modern-button">
              <FaHome className="button-icon" /> Home
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Membership;
