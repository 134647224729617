import React, { useState } from 'react';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth, db } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(''); // State for success messages
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
  
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Check if the user's email is verified
      if (!user.emailVerified) {
        setError('Please verify your email before logging in.');
        setLoading(false);
        return;
      }
  
      const userDoc = await getDoc(doc(db, 'profiles', user.uid));
      
      if (userDoc.exists()) {
        navigate('/home');
      } else {
        navigate('/profile');
      }
  
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.code === 'auth/wrong-password') {
        setError('Incorrect password. Please try again.');
      } else if (err.code === 'auth/user-not-found') {
        setError('No account found with this email.');
      } else {
        setError('Failed to log in. Please try again.');
      }
      console.error('Error during sign-in:', err);
    }
  };
  
  const handleForgotPassword = async () => {
    if (!email) {
      setError('Please enter your email address to reset your password.');
      return;
    }

    setLoading(true);
    setError('');
    setMessage('');

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent! Check your inbox.');
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.code === 'auth/user-not-found') {
        setError('No account found with this email.');
      } else {
        setError('Failed to send reset email. Please try again.');
      }
      console.error('Error sending password reset email:', err);
    }
  };

  return (
    <div className="login-screen">
      {loading ? (
        <div className="loading-screen">
          <div className="spinner"></div>
          <p>Signing you in...</p>
        </div>
      ) : (
        <div className="login-content">
          <h1>Login</h1>
          <form onSubmit={handleLogin}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {error && <p className="error-text">{error}</p>}
            {message && <p className="success-text">{message}</p>}
            <button type="submit">Sign in with Email</button>
          </form>
          <p>
            <button className="forgot-password" onClick={handleForgotPassword}>
              Forgot Password?
            </button>
          </p>
          <p>Don't have an account? <a href="/signup">Sign Up</a></p>
        </div>
      )}
    </div>
  );
};

export default Login;
