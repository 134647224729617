import React from 'react';
import Select from 'react-select';

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    border: 'none',
    boxShadow: '0 0 5px #ffd700, 0 0 10px #ffd700, 0 0 15px #ffd700, 0 0 20px #ffd700',
    color: 'white',
    borderRadius: '25px',
    padding: '5px',
    marginTop: '10px',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    color: 'black',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#ffd700',
    color: 'black',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'black',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'rgba(255, 255, 255, 0.8)',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'black',
    backgroundColor: state.isFocused ? 'rgba(255, 255, 255, 0.3)' : 'white',
  }),
};

const CustomSelect = ({ options, isMulti = false, ...props }) => (
  <Select styles={customStyles} options={options} isMulti={isMulti} {...props} />
);

export default CustomSelect;
