import React, { useState, useEffect, useCallback } from 'react';
import { db, auth } from '../firebaseConfig';
import { collection, query, where, getDocs, getDoc, orderBy, limit, doc, deleteDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { Menu, MenuItem, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './Inbox.css';
import { FaArrowLeft} from 'react-icons/fa';

const Inbox = () => {
  const [chats, setChats] = useState([]);
  const [userNames, setUserNames] = useState({});
  const [userPictures, setUserPictures] = useState({});
  const [latestMessages, setLatestMessages] = useState({});
  const [loading, setLoading] = useState(true);
  const [authLoading, setAuthLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const navigate = useNavigate();

  const fetchUserNamesAndPictures = useCallback(async (chatsList) => {
    const userIds = new Set();
    chatsList.forEach(chat => {
      chat.participants.forEach(id => userIds.add(id));
    });

    const userNamesMap = {};
    const userPicturesMap = {};

    if (userIds.size > 0) {
      const profilesQuery = query(collection(db, 'profiles'), where('__name__', 'in', Array.from(userIds)));
      const profilesSnapshot = await getDocs(profilesQuery);
      profilesSnapshot.forEach(doc => {
        const userData = doc.data();
        userNamesMap[doc.id] = userData.name;
        userPicturesMap[doc.id] = userData.pictures ? userData.pictures[0] : '/default-profile.png';
      });
    }

    setUserNames(prevState => ({ ...prevState, ...userNamesMap }));
    setUserPictures(prevState => ({ ...prevState, ...userPicturesMap }));
  }, []);

  const fetchLatestMessages = async (chatsList) => {
    const latestMessagesMap = {};
    await Promise.all(chatsList.map(async (chat) => {
      const messagesQuery = query(
        collection(db, 'chats', chat.id, 'messages'),
        orderBy('timestamp', 'desc'),
        limit(1)
      );
      const messagesSnapshot = await getDocs(messagesQuery);
      if (!messagesSnapshot.empty) {
        const latestMessage = messagesSnapshot.docs[0].data();
        latestMessagesMap[chat.id] = {
          message: latestMessage.message,
          timestamp: latestMessage.timestamp.toDate()
        };
      }
    }));
    setLatestMessages(latestMessagesMap);
    return latestMessagesMap;
  };

  const deleteChat = async (chatId, otherParticipantId) => {
    try {
      // Delete chat document
      await deleteDoc(doc(db, 'chats', chatId));

      // Add to deleted chats collection
      await setDoc(doc(db, 'deletedChats', auth.currentUser.uid, 'deleted', otherParticipantId), {
        timestamp: new Date()
      });

      // Update chat list
      setChats(prevChats => prevChats.filter(chat => chat.id !== chatId));
    } catch (error) {
      console.error('Error deleting chat: ', error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userId = user.uid;
          const q = query(collection(db, 'chats'), where('participants', 'array-contains', userId));
          const querySnapshot = await getDocs(q);
          let chatsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

          const latestMessagesMap = await fetchLatestMessages(chatsList);

          chatsList = chatsList.map(chat => ({
            ...chat,
            latestMessageTimestamp: latestMessagesMap[chat.id] ? latestMessagesMap[chat.id].timestamp : null
          }));

          chatsList.sort((a, b) => {
            if (a.latestMessageTimestamp && b.latestMessageTimestamp) {
              return b.latestMessageTimestamp - a.latestMessageTimestamp;
            } else if (a.latestMessageTimestamp) {
              return -1;
            } else {
              return 1;
            }
          });

          setChats(chatsList);
          await fetchUserNamesAndPictures(chatsList);
        } catch (error) {
          console.error('Error fetching chats: ', error);
        } finally {
          setLoading(false);
        }
      } else {
        navigate('/login');
      }
      setAuthLoading(false);
    });

    return () => unsubscribe();
  }, [fetchUserNamesAndPictures, navigate]);

  const handleChatClick = (chatId) => {
    navigate(`/chat/${chatId}`);
  };

  const handleMenuOpen = (event, chat) => {
    setAnchorEl(event.currentTarget);
    setSelectedChat(chat);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedChat(null);
  };

  if (authLoading || loading) {
    return <p>Loading...</p>;
  }

  if (chats.length === 0) {
    return (
      <div className="no-messages">
        <h2>No Messages Available</h2>
        <p>There are no messages available at the moment. Please check back later or start a new conversation.</p>
        <button className="retry-button" onClick={() => navigate('/campusconnect')}>Go back to Campus Connect</button>
      </div>
    );
  }

  return (
    <div className="inbox">
      <button className="back-button-inbox" onClick={() => navigate('/campusconnect')}><FaArrowLeft className="button-icon" /> Back
      </button>
      <div className="inbox-content">
        <h1>DMs</h1>
        {chats.map((chat) => (
          <div key={chat.id} className="chat">
            <div className="chat-header" onClick={() => handleChatClick(chat.id)}>
              <img
                src={userPictures[chat.participants.find(id => id !== auth.currentUser.uid)] || '/default-profile.png'}
                alt="Profile"
                className="profile-picture"
              />
              <div className="chat-info">
                <p className="chat-participants">{chat.participants.filter(id => id !== auth.currentUser.uid).map(id => userNames[id] || id).join(', ')}</p>
                {latestMessages[chat.id] && (
                  <p className="message-preview">{latestMessages[chat.id].message}</p>
                )}
              </div>
              {latestMessages[chat.id] && (
                <p className="chat-timestamp">{new Date(latestMessages[chat.id].timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
              )}
            </div>
            <IconButton className="more-icon-inbox" onClick={(e) => handleMenuOpen(e, chat)}>
              <MoreVertIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedChat?.id === chat.id}
              onClose={handleMenuClose}
              className="delete-menu"
            >
              <MenuItem
                className="delete-menu-item"
                onClick={() => {
                  deleteChat(chat.id, chat.participants.find(id => id !== auth.currentUser.uid));
                  handleMenuClose();
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Inbox;
