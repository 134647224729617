import React, { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faBirthdayCake, faUserGraduate, faGlobe, faVenusMars, faComment, faStar, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import './ProfileCard.css';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ProfileCard = ({ profile }) => {
  const [index, setIndex] = useState(0);

  const handleChangeIndex = (index) => {
    setIndex(index);
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % profile.pictures.length);
  };

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 + profile.pictures.length) % profile.pictures.length);
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  useEffect(() => {
    setIndex(0);
  }, [profile]);

  return (
    <div className="profile-card">
      <h2>{profile.name}</h2>
      <div className="swipeable-container">
        <button
          className="arrow-button left-arrow"
          onClick={handlePrev}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <AutoPlaySwipeableViews
          index={index}
          onChangeIndex={handleChangeIndex}
          interval={4000} // Adjust interval as needed
          enableMouseEvents // Ensure mouse events are enabled
        >
          {profile.pictures.map((picture, idx) => (
            <div key={idx} className="image-container">
              <img src={picture} alt={`Profile ${idx + 1}`} />
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <button
          className="arrow-button right-arrow"
          onClick={handleNext}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      <div className="profile-details">
        <div className="detail-item">
          <FontAwesomeIcon icon={faBirthdayCake} className="detail-icon" />
          <p><span className="category-title">Age:</span> <span className="data">{calculateAge(profile.dob)}</span></p>
        </div>
        <div className="detail-item">
          <FontAwesomeIcon icon={faVenusMars} className="detail-icon" />
          <p><span className="category-title">Gender:</span> <span className="data">{profile.gender}</span></p>
        </div>
        <div className="detail-item">
          <FontAwesomeIcon icon={faGraduationCap} className="detail-icon" />
          <p><span className="category-title">Major:</span> <span className="data">{profile.major} @ {profile.university}</span></p>
        </div>
        <div className="detail-item">
          <FontAwesomeIcon icon={faUserGraduate} className="detail-icon" />
          <p><span className="category-title">Graduation Class:</span> <span className="data">{profile.graduationClass}</span></p>
        </div>
        <div className="detail-item">
          <FontAwesomeIcon icon={faGlobe} className="detail-icon" />
          <p><span className="category-title">Ethnicity:</span> <span className="data">{profile.ethnicity}</span></p>
        </div>
        <div className="detail-item">
          <FontAwesomeIcon icon={faStar} className="detail-icon" />
          <p><span className="category-title">Interests:</span> <span className="data">{profile.interests.join(', ')}</span></p>
        </div>
        <div className="detail-item">
          <FontAwesomeIcon icon={faComment} className="detail-icon" />
          <p><span className="category-title">Profile Bio:</span> <span className="data">{profile.description}</span></p>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
