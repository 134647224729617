import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { collection, query, where, getDocs, addDoc, serverTimestamp, doc, getDoc, setDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import ProfileCard from '../components/ProfileCard';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomSelect from '../components/CustomSelect'; // Importing CustomSelect component
import './ProfileSwipe.css';
import { FaArrowLeft, FaPaperPlane, FaTimes } from 'react-icons/fa';

const ProfileSwipe = () => {
  const [profiles, setProfiles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    gender: 'All',
    university: 'All',
    major: 'All',
    ethnicity: 'All',
    graduationClass: 'All',
    interests: 'All'
  });
  const [appliedFilters, setAppliedFilters] = useState({
    gender: 'All',
    university: 'All',
    major: 'All',
    ethnicity: 'All',
    graduationClass: 'All',
    interests: 'All'
  });
  const [noProfilesAvailable, setNoProfilesAvailable] = useState(false);
  const [membership, setMembership] = useState('');
  const navigate = useNavigate();

  const universities = [
    "University of Toronto",
    "University of British Columbia",
    "McGill University",
    "University of Alberta",
    "University of Calgary",
    "University of Waterloo",
    "Western University",
    "Queen's University",
    "McMaster University",
    "University of Ottawa",
    "Simon Fraser University",
    "Dalhousie University",
    "University of Montreal",
    "Université Laval",
    "University of Manitoba",
    "University of Saskatchewan",
    "University of Victoria",
    "York University",
    "University of Guelph",
    "Western University",
    "Toronto Metropolitan University"
  ];


  const majors = [
    "Accounting", "Aerospace Engineering", "Agriculture", "Animal Science", "Anthropology", 
    "Archaeology", "Architecture", "Art History", "Biochemistry", "Biology", 
    "Biomedical Engineering", "Biotechnology", "Business Administration", "Chemical Engineering", 
    "Chemistry", "Civil Engineering", "Classics", "Cognitive Science", "Communications", 
    "Community Development", "Computer Engineering", "Computer Science", "Creative Writing", 
    "Criminal Justice", "Criminology", "Cultural Studies", "Curriculum and Instruction", 
    "Data Science", "Dentistry", "Early Childhood Education", "Economics", "Education", 
    "Educational Leadership", "Electrical Engineering", "Elementary Education", "Engineering", 
    "Entrepreneurship", "Environmental Engineering", "Environmental Policy", "Environmental Science", 
    "Environmental Studies", "Fashion Design", "Film Studies", "Finance", "Fine Arts", 
    "Fisheries and Wildlife", "Forensic Science", "Forestry", "Gender Studies", "Geography", 
    "Geology", "Global Studies", "Graphic Design", "Health Administration", "Health Science", 
    "History", "Horticulture", "Hospitality Management", "Hospitality and Tourism", "Human Development", 
    "Human Resources", "Information Technology", "Interior Design", "International Business", 
    "International Development", "International Relations", "Journalism", "Kinesiology", 
    "Landscape Architecture", "Law", "Legal Studies", "Library Science","Life Science", "Linguistics", 
    "Literature", "Management", "Marine Biology", "Marketing", "Mathematics", 
    "Mechanical Engineering", "Medicine", "Medical Radiation Sciences", "Meteorology", "Military Science", "Music", 
    "Natural Resources Management", "Neuroscience", "Nonprofit Management", "Nuclear Engineering", 
    "Nursing", "Nutrition", "Occupational Therapy", "Paralegal Studies", "Peace and Conflict Studies", 
    "Petroleum Engineering", "Pharmacy", "Philosophy", "Physical Therapy", "Physics", 
    "Political Science", "Pre-Law", "Psychology", "Public Administration", "Public Health", 
    "Public Policy", "Radiology", "Real Estate", "Religious Studies", "Robotics Engineering", 
    "Social Entrepreneurship", "Social Work", "Sociology", "Software Engineering", 
    "Soil Science", "Special Education", "Sports Management", "Sports Medicine", 
    "Statistics", "Sustainability Studies", "Systems Engineering", "Telecommunications", 
    "Textile Design", "Theater", "Urban Planning", "Urban Studies", "Veterinary Medicine", 
    "Visual Arts", "Women's Studies", "World Languages and Cultures", "Zoology"
  ];
  const ethnicities = [
    "American Indian", "Black/African Descent", "East Asian", "Southeast Asian", "Hispanic/Latino", 
    "Middle Eastern", "Pacific Islander", "South Asian", "White/Caucasian", "Other"
  ];

  const interestOptions = [
    { value: 'Sports', label: 'Sports' },
    { value: 'Music', label: 'Music' },
    { value: 'Reading', label: 'Reading' },
    { value: 'Traveling', label: 'Traveling' },
    { value: 'Cooking', label: 'Cooking' },
    { value: 'Gym', label: 'Gym' },
    { value: 'Clubbing', label: 'Clubbing' },
    { value: 'Gaming', label: 'Gaming' },
    { value: 'Movies', label: 'Movies' },
    { value: 'Photography', label: 'Photography' },
    { value: 'Art', label: 'Art' },
    { value: 'Fashion', label: 'Fashion' },
    { value: 'Volunteering', label: 'Volunteering' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Outdoor Activities', label: 'Outdoor Activities' },
    { value: 'Dance', label: 'Dance' },
    { value: 'Social Media', label: 'Social Media' }
  ];

  const graduationClassOptions = [
    { value: '2024', label: '2024' },
    { value: '2025', label: '2025' },
    { value: '2026', label: '2026' },
    { value: '2027', label: '2027' },
    { value: '2028', label: '2028' }
  ];

  const fetchSkippedProfiles = async (userId) => {
    const skippedProfilesDoc = await getDoc(doc(db, 'skippedProfiles', userId));
    return skippedProfilesDoc.exists() ? skippedProfilesDoc.data().profiles : [];
  };

  const setSkippedProfiles = async (userId, skippedProfiles) => {
    await setDoc(doc(db, 'skippedProfiles', userId), { profiles: skippedProfiles }, { merge: true });
  };

  const fetchProfiles = async (userId, filters) => {
    try {
      const skippedProfiles = await fetchSkippedProfiles(userId);
      const directMessagesSnapshot = await getDocs(collection(db, 'directMessages', userId, 'messages'));
      const messagedUserIds = directMessagesSnapshot.docs.map(doc => doc.id);

      let q = query(collection(db, 'profiles'), where('campusCrush', '==', true));

      if (filters.gender && filters.gender !== 'All') {
        q = query(q, where('gender', '==', filters.gender));
      }

      if (filters.university && filters.university !== 'All') {
        q = query(q, where('university', '==', filters.university));
      }

      if (filters.major && filters.major !== 'All') {
        q = query(q, where('major', '==', filters.major));
      }

      if (filters.ethnicity && filters.ethnicity !== 'All') {
        q = query(q, where('ethnicity', '==', filters.ethnicity));
      }

      if (filters.graduationClass && filters.graduationClass !== 'All') {
        q = query(q, where('graduationClass', '==', filters.graduationClass));
      }

      if (filters.interests && filters.interests !== 'All') {
        q = query(q, where('interests', 'array-contains', filters.interests));
      }

      const querySnapshot = await getDocs(q);
      const profilesList = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter(profile => profile.id !== userId && !messagedUserIds.includes(profile.id) && !skippedProfiles.includes(profile.id));

      setProfiles(profilesList);
      setCurrentIndex(0);

      if (profilesList.length === 0) {
        setNoProfilesAvailable(true);
      } else {
        setNoProfilesAvailable(false);
      }
    } catch (error) {
      console.error('Error fetching profiles: ', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMembershipStatus = async (userId) => {
    const userDoc = await getDoc(doc(db, 'profiles', userId));
    if (userDoc.exists()) {
      setMembership(userDoc.data().membership || 'Free');
    } else {
      setMembership('Free');
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchMembershipStatus(user.uid);
        fetchProfiles(user.uid, appliedFilters);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [appliedFilters, navigate]);

  const handleSkip = async () => {
    const userId = auth.currentUser.uid;
    const skippedProfiles = await fetchSkippedProfiles(userId);
    skippedProfiles.push(profiles[currentIndex].id);
    await setSkippedProfiles(userId, skippedProfiles);

    if (currentIndex < profiles.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      setProfiles([]);
      setNoProfilesAvailable(true);
    }
    setMessage('');
  };

  const handleDirectMessage = async () => {
    const receiverId = profiles[currentIndex].id;
    const senderId = auth.currentUser.uid;
    const messageContent = message;
    const senderName = auth.currentUser.displayName || 'Anonymous';

    const now = new Date();
    const oneDayAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);

    const dailyMessagesQuery = query(
      collection(db, 'directMessages', senderId, 'messages'),
      where('timestamp', '>', oneDayAgo)
    );

    const dailyMessagesSnapshot = await getDocs(dailyMessagesQuery);
    const uniqueReceivers = new Set(dailyMessagesSnapshot.docs.map(doc => doc.id));

    if (membership === 'Free' && uniqueReceivers.size >= 7 && !uniqueReceivers.has(receiverId)) {
      toast.error('You have reached the limit of messaging 7 new people today. Please try again in 24 hours or upgrade to CampusCraze Elite.');
      return;
    }

    try {
      const chatId = [senderId, receiverId].sort().join('_');
      const chatRef = doc(db, 'chats', chatId);
      const chatDoc = await getDoc(chatRef);

      if (!chatDoc.exists()) {
        await setDoc(chatRef, {
          participants: [senderId, receiverId],
        });
      }

      await addDoc(collection(db, 'chats', chatId, 'messages'), {
        senderId,
        senderName,
        message: messageContent,
        timestamp: serverTimestamp(),
      });

      await setDoc(doc(db, 'directMessages', senderId, 'messages', receiverId), {
        timestamp: serverTimestamp(),
      });

      setMessage('');
      toast.success('Message sent successfully!');

      fetchProfiles(auth.currentUser.uid, appliedFilters);
    } catch (error) {
      console.error('Error sending message: ', error);
      toast.error('Error sending message. Please try again.');
    }
  };

  const handleFilterChange = (selectedOption, name) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: selectedOption ? selectedOption.value : 'All',
    }));
  };

  const applyFilters = () => {
    setAppliedFilters(filters);
  };

  if (loading) {
    return <p>Loading profiles...</p>;
  }

  if (noProfilesAvailable) {
    return (
      <div className="no-profiles">
        <h2>No Profiles Available</h2>
        <p>There are no profiles that match your criteria. Please adjust your filters or try again later.</p>
        <button className="retry-button-profileswipe" onClick={() => navigate('/campusconnect')}>Try Again</button>
      </div>
    );
  }

  const currentProfile = profiles[currentIndex];

  return (
    <div className="profile-swipe">
      <ToastContainer />
      <button className="back-button-profileswipe" onClick={() => navigate('/campusconnect')}><FaArrowLeft className="button-icon" /> Back
      </button>
      <div className="filter-container">
        <CustomSelect
          name="gender"
          options={[
            { value: 'All', label: 'All Genders' },
            { value: 'Man', label: 'Man' },
            { value: 'Woman', label: 'Woman' },
            { value: 'Non-binary', label: 'Non-binary' },
          ]}
          value={filters.gender ? { value: filters.gender, label: filters.gender === 'All' ? 'All Genders' : filters.gender } : null}
          onChange={(selectedOption) => handleFilterChange(selectedOption, 'gender')}
          placeholder="Gender"
        />
        <CustomSelect
          name="university"
          options={[{ value: 'All', label: 'All Universities' }, ...universities.map((university) => ({ value: university, label: university }))]}
          value={filters.university ? { value: filters.university, label: filters.university === 'All' ? 'All Universities' : filters.university } : null}
          onChange={(selectedOption) => handleFilterChange(selectedOption, 'university')}
          placeholder="University"
        />
        <CustomSelect
          name="major"
          options={[{ value: 'All', label: 'All Majors' }, ...majors.map((major) => ({ value: major, label: major }))]}
          value={filters.major ? { value: filters.major, label: filters.major === 'All' ? 'All Majors' : filters.major } : null}
          onChange={(selectedOption) => handleFilterChange(selectedOption, 'major')}
          placeholder="Major"
        />
        <CustomSelect
          name="ethnicity"
          options={[{ value: 'All', label: 'All Ethnicities' }, ...ethnicities.map((ethnicity) => ({ value: ethnicity, label: ethnicity }))]}
          value={filters.ethnicity ? { value: filters.ethnicity, label: filters.ethnicity === 'All' ? 'All Ethnicities' : filters.ethnicity } : null}
          onChange={(selectedOption) => handleFilterChange(selectedOption, 'ethnicity')}
          placeholder="Ethnicity"
        />
        <CustomSelect
          name="graduationClass"
          options={graduationClassOptions}
          value={filters.graduationClass ? { value: filters.graduationClass, label: filters.graduationClass === 'All' ? 'All Graduation Classes' : filters.graduationClass } : null}
          onChange={(selectedOption) => handleFilterChange(selectedOption, 'graduationClass')}
          placeholder="Graduation Class"
        />
        <CustomSelect
          name="interests"
          options={interestOptions}
          value={filters.interests ? { value: filters.interests, label: filters.interests === 'All' ? 'All Interests' : filters.interests } : null}
          onChange={(selectedOption) => handleFilterChange(selectedOption, 'interests')}
          placeholder="Interests"
        />
        <button onClick={applyFilters}>Apply Filters</button>
      </div>
      <div className="profile-card-container">
        <ProfileCard profile={currentProfile} />
        <div className="profile-actions">
          <button className="skip-button" onClick={handleSkip}><FaTimes className="button-icon" /> Skip
          </button>
          <input 
            type="text" 
            value={message} 
            onChange={(e) => setMessage(e.target.value)} 
            placeholder="Type a message" 
          />
          <button className="direct-message-button" onClick={handleDirectMessage}><FaPaperPlane className="button-icon" /> Message
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSwipe;
