import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { db } from '../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, 'profiles', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCurrentUser({
            ...user,
            ...userData, // This includes stripeCustomerId
          });
        } else {
          setCurrentUser(user);
        }
      } else {
        setCurrentUser(null);
      }
    });

    return unsubscribe;
  }, []);

  const updateMembershipStatus = async (membershipStatus) => {
    if (currentUser) {
      const userRef = doc(db, 'profiles', currentUser.uid);
      await updateDoc(userRef, {
        membership: membershipStatus
      });
      setCurrentUser((prevUser) => ({
        ...prevUser,
        membership: membershipStatus
      }));
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, updateMembershipStatus }}>
      {children}
    </AuthContext.Provider>
  );
};
