import React, { useState } from 'react';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { auth } from '../firebaseConfig'; // Import your Firebase auth instance
import { useNavigate, Link } from 'react-router-dom';
import './Signup.css';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // New state for confirm password
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const [message, setMessage] = useState(''); // Message state for success messages
  const navigate = useNavigate();

  const handleRegister = async () => {
    setError(''); // Clear any previous errors
    setMessage(''); // Clear any previous messages
    setLoading(true); // Start loading

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      setLoading(false);
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Send email verification
      await sendEmailVerification(user);

      // Show success message and reset form
      setMessage('Registration successful! Please check your email to verify your account.');
      setLoading(false); // Stop loading
      setEmail(''); // Clear the email input
      setPassword(''); // Clear the password input
      setConfirmPassword(''); // Clear the confirm password input
    } catch (err) {
      setLoading(false); // Stop loading on error
      if (err.code === 'auth/email-already-in-use') {
        setError('The email address is already in use by another account.');
      } else if (err.code === 'auth/weak-password') {
        setError('Password must be at least 6 characters long.'); // Custom error message for weak password
      } else {
        setError('Failed to register. Please try again.');
      }
      console.error('Error during registration:', err);
    }
  };

  return (
    <div className="signup-screen">
      {loading ? (
        <div className="loading-screen">
          <div className="spinner"></div>
          <p>Registering your account...</p>
        </div>
      ) : (
        <div className="signup-content">
          <h1>Sign Up</h1>
          {message && <p className="success-text">{message}</p>} {/* Show success message */}
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm Password"
            required
          />
          {error && <p className="error-text">{error}</p>}
          <button onClick={handleRegister}>Sign Up</button>
          <Link to="/">
            <button>Home</button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Signup;
