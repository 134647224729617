import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-4D2CKCWXL9'); // Initialize only once
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  return null; // This component doesn't render anything
};

export default Analytics;
