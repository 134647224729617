import React, { useState } from 'react';
import { db, auth } from '../firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import CustomSelect from './CustomSelect';
import './ProfileForm.css';

const ProfileForm = () => {
  const [profile, setProfile] = useState({
    name: '',
    dob: '',
    major: '',
    ethnicity: '',
    gender: '',
    description: '',
    interests: [],
    university: '',
    graduationClass: '',
    campusCrush: true,
    pictures: [],
  });
  const [pictureFiles, setPictureFiles] = useState([null, null, null, null, null, null]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State for loading screen
  const [currentStep, setCurrentStep] = useState(0);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const storage = getStorage();

  const majors = [
    "Accounting", "Aerospace Engineering", "Agriculture", "Animal Science", "Anthropology", 
    "Archaeology", "Architecture", "Art History", "Biochemistry", "Biology", 
    "Biomedical Engineering", "Biotechnology", "Business Administration", "Chemical Engineering", 
    "Chemistry", "Civil Engineering", "Classics", "Cognitive Science", "Communications", 
    "Community Development", "Computer Engineering", "Computer Science", "Creative Writing", 
    "Criminal Justice", "Criminology", "Cultural Studies", "Curriculum and Instruction", 
    "Data Science", "Dentistry", "Early Childhood Education", "Economics", "Education", 
    "Educational Leadership", "Electrical Engineering", "Elementary Education", "Engineering", 
    "Entrepreneurship", "Environmental Engineering", "Environmental Policy", "Environmental Science", 
    "Environmental Studies", "Fashion Design", "Film Studies", "Finance", "Fine Arts", 
    "Fisheries and Wildlife", "Forensic Science", "Forestry", "Gender Studies", "Geography", 
    "Geology", "Global Studies", "Graphic Design", "Health Administration", "Health Science", 
    "History", "Horticulture", "Hospitality Management", "Hospitality and Tourism", "Human Development", 
    "Human Resources", "Information Technology", "Interior Design", "International Business", 
    "International Development", "International Relations", "Journalism", "Kinesiology", 
    "Landscape Architecture", "Law", "Legal Studies", "Library Science","Life Science", "Linguistics", 
    "Literature", "Management", "Marine Biology", "Marketing", "Mathematics", 
    "Mechanical Engineering", "Medicine", "Medical Radiation Sciences", "Meteorology", "Military Science", "Music", 
    "Natural Resources Management", "Neuroscience", "Nonprofit Management", "Nuclear Engineering", 
    "Nursing", "Nutrition", "Occupational Therapy", "Paralegal Studies", "Peace and Conflict Studies", 
    "Petroleum Engineering", "Pharmacy", "Philosophy", "Physical Therapy", "Physics", 
    "Political Science", "Pre-Law", "Psychology", "Public Administration", "Public Health", 
    "Public Policy", "Radiology", "Real Estate", "Religious Studies", "Robotics Engineering", 
    "Social Entrepreneurship", "Social Work", "Sociology", "Software Engineering", 
    "Soil Science", "Special Education", "Sports Management", "Sports Medicine", 
    "Statistics", "Sustainability Studies", "Systems Engineering", "Telecommunications", 
    "Textile Design", "Theater", "Urban Planning", "Urban Studies", "Veterinary Medicine", 
    "Visual Arts", "Women's Studies", "World Languages and Cultures", "Zoology"
  ];

  const ethnicities = [
    "American Indian", "Black/African Descent", "East Asian", "Southeast Asian", "Hispanic/Latino", 
    "Middle Eastern", "Pacific Islander", "South Asian", "White/Caucasian", "Other"
  ];

  const universities = [
    "University of Toronto",
    "University of British Columbia",
    "McGill University",
    "University of Alberta",
    "University of Calgary",
    "University of Waterloo",
    "Western University",
    "Queen's University",
    "McMaster University",
    "University of Ottawa",
    "Simon Fraser University",
    "Dalhousie University",
    "University of Montreal",
    "Université Laval",
    "University of Manitoba",
    "University of Saskatchewan",
    "University of Victoria",
    "York University",
    "University of Guelph",
    "Western University",
    "Toronto Metropolitan University"
  ];

  const interestOptions = [
    { value: 'Sports', label: 'Sports' },
    { value: 'Music', label: 'Music' },
    { value: 'Reading', label: 'Reading' },
    { value: 'Traveling', label: 'Traveling' },
    { value: 'Cooking', label: 'Cooking' },
    { value: 'Gym', label: 'Gym' },
    { value: 'Clubbing', label: 'Clubbing' },
    { value: 'Gaming', label: 'Gaming' },
    { value: 'Movies', label: 'Movies' },
    { value: 'Photography', label: 'Photography' },
    { value: 'Art', label: 'Art' },
    { value: 'Fashion', label: 'Fashion' },
    { value: 'Volunteering', label: 'Volunteering' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Outdoor Activities', label: 'Outdoor Activities' },
    { value: 'Dance', label: 'Dance' },
    { value: 'Social Media', label: 'Social Media' }
  ];

  const graduationClassOptions = [
    { value: '2024', label: '2024' },
    { value: '2025', label: '2025' },
    { value: '2026', label: '2026' },
    { value: '2027', label: '2027' },
    { value: '2028', label: '2028' }
  ];

  const handlePictureChange = (index, file) => {
    const updatedPictures = [...pictureFiles];
    updatedPictures[index] = file;
    setPictureFiles(updatedPictures);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return;
    setIsSubmitting(true);
    setIsLoading(true); // Show loading screen

    const user = auth.currentUser;
    if (!user) {
      console.error('User is not authenticated');
      navigate('/login');
      return;
    }

    const userId = user.uid;
    const pictureURLs = await Promise.all(
      pictureFiles.map(async (file, index) => {
        if (file) {
          const storageRef = ref(storage, `profiles/${userId}/picture_${index}`);
          await uploadBytes(storageRef, file);
          const url = await getDownloadURL(storageRef);
          return url;
        }
        return null;
      })
    );

    try {
      await setDoc(doc(db, 'profiles', userId), {
        ...profile,
        interests: profile.interests.map((interest) => interest.value),
        pictures: pictureURLs.filter((url) => url !== null),
        userId,
        membership: 'Free'
      });
      console.log('Profile created successfully:', profile);
      navigate('/home');
    } catch (error) {
      console.error('Error creating profile:', error);
    } finally {
      setIsSubmitting(false);
      setIsLoading(false); // Hide loading screen
    }
  };

  const nextStep = () => {
    if (validateStep(currentStep)) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const previousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const validateStep = (step) => {
    const newErrors = {};

    switch (step) {
      case 0:
        if (!profile.name.trim()) newErrors.name = 'Name is required';
        break;
      case 1:
        if (!profile.dob) newErrors.dob = 'Date of Birth is required';
        break;
      case 2:
        if (!profile.major) newErrors.major = 'Major is required';
        break;
      case 3:
        if (!profile.ethnicity) newErrors.ethnicity = 'Ethnicity is required';
        break;
      case 4:
        if (!profile.gender) newErrors.gender = 'Gender is required';
        break;
      case 5:
        if (!profile.description.trim()) newErrors.description = 'Profile Bio is required';
        break;
      case 6:
        if (profile.interests.length === 0) newErrors.interests = 'Interests are required';
        break;
      case 7:
        if (!profile.university) newErrors.university = 'University is required';
        break;
      case 8:
        if (!profile.graduationClass) newErrors.graduationClass = 'Graduation Class is required';
        break;
      default:
        break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const renderStep = () => {
    return (
      <div key={currentStep} className="profile-step fade-in">
        {getStepContent(currentStep)}
      </div>
    );
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <label>
            Name:
            <input type="text" name="name" value={profile.name} onChange={(e) => setProfile({ ...profile, name: e.target.value })} placeholder="Name" required />
            {errors.name && <span className="error-message">{errors.name}</span>}
          </label>
        );
      case 1:
        return (
          <label>
            Date of Birth:
            <input type="date" name="dob" value={profile.dob} onChange={(e) => setProfile({ ...profile, dob: e.target.value })} placeholder="Date of Birth" required />
            {errors.dob && <span className="error-message">{errors.dob}</span>}
          </label>
        );
      case 2:
        return (
          <label>
            Major:
            <CustomSelect
              name="major"
              options={majors.map((major) => ({ value: major, label: major }))}
              value={{ value: profile.major, label: profile.major }}
              onChange={(selectedOption) => setProfile({ ...profile, major: selectedOption.value })}
              required
            />
            {errors.major && <span className="error-message">{errors.major}</span>}
          </label>
        );
      case 3:
        return (
          <label>
            Ethnicity:
            <CustomSelect
              name="ethnicity"
              options={ethnicities.map((ethnicity) => ({ value: ethnicity, label: ethnicity }))}
              value={{ value: profile.ethnicity, label: profile.ethnicity }}
              onChange={(selectedOption) => setProfile({ ...profile, ethnicity: selectedOption.value })}
              required
            />
            {errors.ethnicity && <span className="error-message">{errors.ethnicity}</span>}
          </label>
        );
      case 4:
        return (
          <label>
            Gender:
            <CustomSelect
              name="gender"
              options={[
                { value: 'Man', label: 'Man' },
                { value: 'Woman', label: 'Woman' },
                { value: 'Non-binary', label: 'Non-binary' },
              ]}
              value={{ value: profile.gender, label: profile.gender }}
              onChange={(selectedOption) => setProfile({ ...profile, gender: selectedOption.value })}
              required
            />
            {errors.gender && <span className="error-message">{errors.gender}</span>}
          </label>
        );
      case 5:
        return (
          <label>
            Profile Bio:
            <textarea name="description" value={profile.description} onChange={(e) => setProfile({ ...profile, description: e.target.value })} placeholder="Profile Bio" required></textarea>
            {errors.description && <span className="error-message">{errors.description}</span>}
          </label>
        );
      case 6:
        return (
          <label>
            Interests (Select Multiple):
            <CustomSelect
              isMulti
              name="interests"
              options={interestOptions}
              value={profile.interests}
              onChange={(selectedOptions) => setProfile({ ...profile, interests: selectedOptions })}
              required
            />
            {errors.interests && <span className="error-message">{errors.interests}</span>}
          </label>
        );
      case 7:
        return (
          <label>
            University:
            <CustomSelect
              name="university"
              options={universities.map((university) => ({ value: university, label: university }))}
              value={{ value: profile.university, label: profile.university }}
              onChange={(selectedOption) => setProfile({ ...profile, university: selectedOption.value })}
              required
            />
            {errors.university && <span className="error-message">{errors.university}</span>}
          </label>
        );
      case 8:
        return (
          <label>
            Graduation Class:
            <CustomSelect
              name="graduationClass"
              options={graduationClassOptions}
              value={{ value: profile.graduationClass, label: profile.graduationClass }}
              onChange={(selectedOption) => setProfile({ ...profile, graduationClass: selectedOption.value })}
              required
            />
            {errors.graduationClass && <span className="error-message">{errors.graduationClass}</span>}
          </label>
        );
      case 9:
        return (
          <label>
            Appear on Campus Connect:
            <div className="campus-crush-description">Your profile will be visible to others.</div>
            <div className={`toggle-switch ${profile.campusCrush ? 'on' : 'off'}`} onClick={() => setProfile({ ...profile, campusCrush: !profile.campusCrush })}>
              <div className={`toggle-slider ${profile.campusCrush ? 'on' : 'off'}`}></div>
            </div>
          </label>
        );
      case 10:
        return (
          <>
            <label>Upload Pictures:</label>
            <div className="picture-inputs">
              {[0, 1, 2, 3, 4, 5].map((index) => (
                <div key={index} className="picture-preview" onClick={() => document.getElementById(`file-input-${index}`).click()}>
                  {pictureFiles[index] ? (
                    <img src={URL.createObjectURL(pictureFiles[index])} alt={`Preview ${index}`} />
                  ) : (
                    <span>+</span>
                  )}
                  <input
                    type="file"
                    id={`file-input-${index}`}
                    className="custom-file-input"
                    onChange={(e) => handlePictureChange(index, e.target.files[0])}
                  />
                </div>
              ))}
            </div>
            <div className="form-navigation">
              <button type="button" onClick={previousStep}>Back</button>
              <button type="submit" disabled={isSubmitting}>Create Profile</button>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && (
        <div className="loading-screen">
          <p>Creating Profile...</p>
        </div>
      )}
      <div className={`profile-form-container ${isLoading ? 'blurred' : ''}`}>
        <form className="profile-form" onSubmit={handleSubmit}>
          {renderStep()}
          {currentStep < 10 && (
            <div className="form-navigation">
              {currentStep > 0 && <button type="button" onClick={previousStep}>Back</button>}
              <button type="button" onClick={nextStep}>Next</button>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default ProfileForm;
