import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { doc, updateDoc, arrayUnion, arrayRemove, onSnapshot, getDoc, getDocs, collection } from 'firebase/firestore';
import './PartyCard.css';
import { FaHeart, FaClock, FaMapMarkerAlt, FaAlignLeft } from 'react-icons/fa';
import RSVPModal from './RSVPModal';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const PartyCard = ({ party, onRSVP, onCancelRSVP, onDelete, userHasRSVPd, isOwner, onUpdateParties }) => {
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState(party.likes || 0); // Ensure likes is a number
  const [userId, setUserId] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [rsvps, setRsvps] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserId(user.uid);
      setLiked(party.likedBy && Array.isArray(party.likedBy) && party.likedBy.includes(user.uid));
    }

    const partyRef = doc(db, 'parties', party.id);
    const unsubscribe = onSnapshot(partyRef, (doc) => {
      if (doc.exists()) {
        const partyData = doc.data();
        setLikes(partyData.likes || 0); // Ensure likes is a number
        setLiked(partyData.likedBy && Array.isArray(partyData.likedBy) && partyData.likedBy.includes(userId));
      }
    });

    return () => unsubscribe();
  }, [party.id, userId]);

  const fetchRsvps = async () => {
    if (party.rsvps.length > 0) {
      try {
        const rsvpList = await Promise.all(
          party.rsvps.map(async (rsvpUserId) => {
            const userDoc = await getDoc(doc(db, 'profiles', rsvpUserId));
            if (userDoc.exists()) {
              return userDoc.data();
            } else {
              console.error(`User document for ID ${rsvpUserId} does not exist.`);
              return { id: rsvpUserId, name: 'Unknown User' };
            }
          })
        );
        setRsvps(rsvpList);
      } catch (error) {
        console.error('Error fetching RSVPs:', error);
      }
    } else {
      setRsvps([]);
    }
  };

  const handleLike = async (e) => {
    e.stopPropagation(); // Prevent click event from reaching the card
    if (!userId) return;

    const partyRef = doc(db, 'parties', party.id);
    const newLikes = liked ? likes - 1 : likes + 1;

    await updateDoc(partyRef, {
      likes: newLikes,
      likedBy: liked ? arrayRemove(userId) : arrayUnion(userId),
    });

    setLiked(!liked);
  };

  const handleDelete = async (e) => {
    e.stopPropagation(); // Prevent click event from reaching the card
    await onDelete(party.id);
    const allPartiesSnapshot = await getDocs(collection(db, 'parties'));
    if (allPartiesSnapshot.empty) {
      // If no parties left, show all dates
      onUpdateParties(null);
    }
  };

  const partyDate = dayjs(party.date).utc();

  return (
    <>
      <div
        className={`party-card ${party.createdByMembership === 'Elite' ? 'elite-party' : 'free-party'}`}
        onClick={() => setShowDetails(!showDetails)}
      >
        <div className="party-image-container">
          <img src={party.picture} alt={party.name} className="party-image" />
          <div className="like-section" onClick={handleLike}>
            <FaHeart className={`heart-icon ${liked ? 'liked' : ''}`} />
            <span>{likes}</span>
          </div>
          {!showDetails && (
            <div className="party-text-overlay">
              <div className="party-date">
                <span className="party-month">{partyDate.tz(dayjs.tz.guess()).format('MMM').toUpperCase()}</span>
                <span className="party-day">{partyDate.tz(dayjs.tz.guess()).format('D')}</span>
                <span className="party-year">{partyDate.tz(dayjs.tz.guess()).format('YYYY')}</span>
              </div>
              <div className="party-name">{party.name}</div>
              <div className="party-university">{party.university}</div>
            </div>
          )}
        </div>
        {showDetails && (
          <div className="party-details">
            <div className="detail-item">
              <FaClock className="detail-icon" />
              <p>{new Date(`1970-01-01T${party.time}`).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</p>
            </div>
            <div className="detail-item">
              <FaMapMarkerAlt className="detail-icon" />
              <p>{party.address}</p>
            </div>
            <div className="detail-item">
              <FaAlignLeft className="detail-icon" />
              <p className="party-description">{party.description}</p>
            </div>
            <div className="rsvp-section">
              <p>RSVPs: {party.rsvps.length}</p>
              {userHasRSVPd ? (
                <button className="rsvp-button cancel" onClick={(e) => { e.stopPropagation(); onCancelRSVP(party.id); }}>Cancel RSVP</button>
              ) : (
                <button className="rsvp-button" onClick={(e) => { e.stopPropagation(); onRSVP(party.id); }}>RSVP</button>
              )}
            </div>
            {isOwner && (
              <>
                <button className="delete-button" onClick={handleDelete}>Delete Party</button>
                <button
                  className="view-rsvp-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    fetchRsvps();
                    setIsModalOpen(true);
                  }}
                >
                  View RSVPs
                </button>
              </>
            )}
          </div>
        )}
      </div>
      {isOwner && (
        <RSVPModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          rsvps={rsvps}
        />
      )}
    </>
  );
};

export default PartyCard;
