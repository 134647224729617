import React from 'react';
import Hero from '../components/Hero';
import About from '../components/About';
import Features from '../components/Features';
import FAQ from '../components/FAQ'; // Import the FAQ component
import Navbar from '../components/Navbar'; // Import the Navbar component
import "./LandingPage.css";

function LandingPage() {
    return (
        <div className="LandingPage">
            <Navbar /> {/* Add the Navbar component here */}
            <Hero />
            <About />
            <Features />
            <FAQ /> {/* Add the FAQ component here */}
        </div>
    )
}

export default LandingPage;
