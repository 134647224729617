// src/pages/Cancel.js
import React from 'react';

import { useSearchParams, Link } from 'react-router-dom';

const Cancel = () => {
  return (
    <div>
      <h1>Payment Canceled</h1>
      <p>Your payment was canceled. You can try again.</p>
      <Link to="/membership">
          <button className="modern-button">Continue</button>
        </Link>
    </div>
  );
};

export default Cancel;
