import React, { useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { db } from '../firebaseConfig'; // Adjust the import according to your project structure
import { doc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';

const API_URL = 'https://us-central1-campuscraze-2dc3b.cloudfunctions.net/api';

const Success = () => {
  const { updateMembershipStatus } = useAuth();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    const updateMembership = async () => {
      try {
        console.log('Fetching session details for session_id:', sessionId);
        const response = await fetch(`${API_URL}/retrieve-checkout-session?session_id=${sessionId}`);
        const session = await response.json();
        console.log('Fetched session details:', session);

        if (session && session.payment_status === 'paid') {
          const userId = session.metadata?.userId;
          console.log('User ID from session metadata:', userId);
          if (!userId) {
            throw new Error('User ID not found in session metadata');
          }

          const userRef = doc(db, 'profiles', userId);
          await updateDoc(userRef, { membership: 'Elite' });
          await updateMembershipStatus('Elite');
          console.log('Membership status updated to Elite for userId:', userId);
        } else {
          console.error('Payment not successful or session invalid');
        }
      } catch (error) {
        console.error('Error updating membership status:', error);
      }
    };

    if (sessionId) {
      updateMembership();
    }
  }, [sessionId, updateMembershipStatus]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', textAlign: 'center' }}>
      <div>
        <h1>Payment successful</h1>
        <p>You've been upgraded to CampusCraze Elite</p>
        <Link to="/membership">
          <button className="modern-button">Continue</button>
        </Link>
      </div>
    </div>
  );
};

export default Success;
