import React from "react";
import { useNavigate } from 'react-router-dom';
import "./Hero.css"; // Import the CSS for Hero

const Hero = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/login'); // Redirect to the Login page
  };

  return (
    <div id="home" className="home-container">
      <div className="home-banner-container">
        <div className="home-text-section">
          <h1 className="primary-heading">
            An all-in-one hub for the <span className="highlight">DREAM</span> university&nbsp;experience!
          </h1>
          <p className="primary-text">
            Whether you want to discover or organize the best university events with CampusCraze, or connect with like-minded people via CampusConnect, we’re here to help you build your dream university experience! Are you ready to embark on an unforgettable journey with CampusCraze?
          </p>
          <button className="auth-button" onClick={handleLogin}>
            Begin your journey here!
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
