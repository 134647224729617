import React, { useState } from "react";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import campusCrazeLogo from '../images/CC.png'; // Add your logo image
import "./Navbar.css"; // Import the CSS for Navbar

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const menuOptions = [
    {
      text: "Home",
      icon: <HomeIcon />,
      link: "#home"
    },
    {
      text: "About",
      icon: <InfoIcon />,
      link: "#about"
    },
    {
      text: "Features",
      icon: <CommentRoundedIcon />,
      link: "#features"
    },
    {
      text: "FAQ",
      icon: <QuestionAnswerIcon />,
      link: "#faq"
    },
  ];

  const handleSmoothScroll = (e, link) => {
    e.preventDefault();
    document.querySelector(link).scrollIntoView({
      behavior: 'smooth'
    });
    setOpenMenu(false); // Close the menu if opened
  };

  return (
    <nav>
      <div className="nav-logo-container">
        <img src={campusCrazeLogo} alt="CampusCraze Logo" />
      </div>
      <div className="navbar-links-container">
        {menuOptions.map((item) => (
          <a key={item.text} href={item.link} onClick={(e) => handleSmoothScroll(e, item.link)}>{item.text}</a>
        ))}
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250, backgroundColor: '#1a1a2e', color: 'white', height: '100%' }} // Styling for the drawer
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton component="a" href={item.link} onClick={(e) => handleSmoothScroll(e, item.link)}>
                  <ListItemIcon style={{ color: '#ffd700' }}>{item.icon}</ListItemIcon> {/* Gold color for icons */}
                  <ListItemText primary={item.text} primaryTypographyProps={{ style: { color: '#ffd700' } }} /> {/* Gold color for text */}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </nav>
  );
};

export default Navbar;
