import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import './CampusCrush.css';
import campusCrushLogo from '../images/campusconnect.png';
import { FaHome, FaHeart, FaEnvelope } from 'react-icons/fa'; // Importing icons

const CampusCrush = () => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkCampusCrushAccess = async () => {
      const user = auth.currentUser;
      if (user) {
        const profileRef = doc(db, 'profiles', user.uid);
        const profileSnap = await getDoc(profileRef);
        if (profileSnap.exists()) {
          const profileData = profileSnap.data();
          setProfile(profileData);
          if (!profileData.campusCrush) {
            navigate('/home'); // Redirect if Campus Crush is not enabled
          }
        } else {
          console.log('No such profile!');
          navigate('/home'); // Redirect if no profile found
        }
      } else {
        navigate('/'); // Redirect if not authenticated
      }
      setLoading(false);
    };

    checkCampusCrushAccess();
  }, [navigate]);

  if (loading) {
    return (
      <div className="loading-screen">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="campus-crush">
      <img src={campusCrushLogo} alt="Campus Crush Logo" className="logo" />
      <div className="button-container">
        <Link to="/home">
          <button className="modern-button">
            <FaHome className="button-icon" /> Home
          </button>
        </Link>
        <Link to="/profile-swipe">
          <button className="modern-button">
            <FaHeart className="button-icon" /> Start Connecting
          </button>
        </Link>
        <Link to="/inbox">
  <button className="modern-button">
    <FaEnvelope className="button-icon" /> DM<span className="small-s">s</span>
  </button>
</Link>

      </div>
    </div>
  );
};

export default CampusCrush;
