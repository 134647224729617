import React from 'react';
import { logout } from '../services/authService';
import { useAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { FaUser, FaSignOutAlt } from 'react-icons/fa';
import campusCrushImage from '../images/campusconnect.png'; // Replace with the correct image path
import campusCrazeImage from '../images/campuscraze.png'; // Replace with the correct image path
import './Home.css';
import { FaHome, FaPlus } from 'react-icons/fa';
const Home = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/'); // Navigate to the initial screen
  };

  return (
    <div className="home">
      <div className="home-content">
        <h1>Welcome to Campus Craze!</h1>
        {currentUser ? (
          <div className="logged-in">
            <p>Glad to see you, ready to explore?{currentUser.displayName}</p>
            <div className="navigation-images">
              <Link to="/campuscraze">
                <img id="campusCrazeImage" src={campusCrazeImage} alt="Campus Craze" />
              </Link>
              <Link to="/campusconnect">
                <img id="campusCrushImage" src={campusCrushImage} alt="Campus Crush" />
              </Link>
            </div>
            <Link to="/view-profile">
              <button className="modern-button">
                <FaUser className="button-icon" /> View Your Profile
              </button>
            </Link>
            <Link to="/membership">
              <button className="modern-button">
                <FaUser className="button-icon" /> View Membership
              </button>
            </Link>
            <button className="modern-button" onClick={handleLogout}>
              <FaSignOutAlt className="button-icon" /> Logout
            </button>
          </div>
        ) : (
          <div className="not-logged-in">
            <p>Please try logging in again.</p>
            <Link to="/">
              <button className="modern-button">
                <FaHome className="button-icon" /> Home
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
