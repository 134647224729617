import React from 'react';
import './About.css'; // Import the CSS for About

const About = () => {
  return (
    <div id="about" className="about-container"> {/* Added ID here */}
      <h2 className="about-heading">About CampusCraze</h2>
      <p className="about-text">
      Our mission is simple - we provide an all-in-one hub for you to discover and organize events and connect with like-minded people as easily as possible.
      </p>
      <p className="about-text">
      Our first core feature is CampusCraze, which allows you to easily find exciting events around your campus (parties, pick-up games, gym, movie nights, bar hop, foodie tour, thrifting, and more), ALL IN ONE HUB! Additionally, our built-in tools and designs help facilitate effortless event management for organizers.
      </p>
      <p className="about-text">
      Our second core feature, CampusConnect, allows you to easily find and connect with others in a secure, private, and inclusive platform - tailored for university students! Discover students in the same major, graduation class, or with shared interests - find study buddies, mentors, new friends, or potential romantic connections! Our direct messaging feature lets you skip the swipe and just vibe!
      </p>
    </div>
  );
};

export default About;
