// RSVPModal.js
import React from 'react';
import './RSVPModal.css';

const RSVPModal = ({ isOpen, onClose, rsvps }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>Close</button>
        <h3>RSVP List</h3>
        <ul>
          {rsvps.map((rsvp, index) => (
            <li key={index}>{rsvp.name}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RSVPModal;
