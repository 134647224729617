import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { Link, useNavigate } from 'react-router-dom';
import CustomSelect from '../components/CustomSelect';
import './ProfileView.css';
import { FaHome } from 'react-icons/fa';

const ProfileView = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState({
    name: '',
    dob: '',
    major: '',
    ethnicity: '',
    gender: '',
    description: '',
    interests: [],
    university: '',
    campusCrush: true,
    pictures: [],
    graduationClass: '',
  });
  const [newPictures, setNewPictures] = useState([null, null, null, null, null, null]);
  const [selectedFileNames, setSelectedFileNames] = useState([null, null, null, null, null, null]);
  const storage = getStorage();
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  const majors = [
    "Accounting", "Aerospace Engineering", "Agriculture", "Animal Science", "Anthropology", 
    "Archaeology", "Architecture", "Art History", "Biochemistry", "Biology", 
    "Biomedical Engineering", "Biotechnology", "Business Administration", "Chemical Engineering", 
    "Chemistry", "Civil Engineering", "Classics", "Cognitive Science", "Communications", 
    "Community Development", "Computer Engineering", "Computer Science", "Creative Writing", 
    "Criminal Justice", "Criminology", "Cultural Studies", "Curriculum and Instruction", 
    "Data Science", "Dentistry", "Early Childhood Education", "Economics", "Education", 
    "Educational Leadership", "Electrical Engineering", "Elementary Education", "Engineering", 
    "Entrepreneurship", "Environmental Engineering", "Environmental Policy", "Environmental Science", 
    "Environmental Studies", "Fashion Design", "Film Studies", "Finance", "Fine Arts", 
    "Fisheries and Wildlife", "Forensic Science", "Forestry", "Gender Studies", "Geography", 
    "Geology", "Global Studies", "Graphic Design", "Health Administration", "Health Science", 
    "History", "Horticulture", "Hospitality Management", "Hospitality and Tourism", "Human Development", 
    "Human Resources", "Information Technology", "Interior Design", "International Business", 
    "International Development", "International Relations", "Journalism", "Kinesiology", 
    "Landscape Architecture", "Law", "Legal Studies", "Library Science","Life Science", "Linguistics", 
    "Literature", "Management", "Marine Biology", "Marketing", "Mathematics", 
    "Mechanical Engineering", "Medicine", "Medical Radiation Sciences", "Meteorology", "Military Science", "Music", 
    "Natural Resources Management", "Neuroscience", "Nonprofit Management", "Nuclear Engineering", 
    "Nursing", "Nutrition", "Occupational Therapy", "Paralegal Studies", "Peace and Conflict Studies", 
    "Petroleum Engineering", "Pharmacy", "Philosophy", "Physical Therapy", "Physics", 
    "Political Science", "Pre-Law", "Psychology", "Public Administration", "Public Health", 
    "Public Policy", "Radiology", "Real Estate", "Religious Studies", "Robotics Engineering", 
    "Social Entrepreneurship", "Social Work", "Sociology", "Software Engineering", 
    "Soil Science", "Special Education", "Sports Management", "Sports Medicine", 
    "Statistics", "Sustainability Studies", "Systems Engineering", "Telecommunications", 
    "Textile Design", "Theater", "Urban Planning", "Urban Studies", "Veterinary Medicine", 
    "Visual Arts", "Women's Studies", "World Languages and Cultures", "Zoology"
  ];

  const ethnicities = [
    "American Indian", "Black/African Descent", "East Asian", "Southeast Asian", "Hispanic/Latino", 
    "Middle Eastern", "Pacific Islander", "South Asian", "White/Caucasian", "Other"
  ];

  const universities = [
    "University of Toronto",
    "University of British Columbia",
    "McGill University",
    "University of Alberta",
    "University of Calgary",
    "University of Waterloo",
    "Western University",
    "Queen's University",
    "McMaster University",
    "University of Ottawa",
    "Simon Fraser University",
    "Dalhousie University",
    "University of Montreal",
    "Université Laval",
    "University of Manitoba",
    "University of Saskatchewan",
    "University of Victoria",
    "York University",
    "University of Guelph",
    "Western University",
    "Toronto Metropolitan University"
  ];

const interestOptions = [
    { value: 'Sports', label: 'Sports' },
    { value: 'Music', label: 'Music' },
    { value: 'Reading', label: 'Reading' },
    { value: 'Traveling', label: 'Traveling' },
    { value: 'Cooking', label: 'Cooking' },
    { value: 'Gym', label: 'Gym' },
    { value: 'Clubbing', label: 'Clubbing' },
    { value: 'Gaming', label: 'Gaming' },
    { value: 'Movies', label: 'Movies' },
    { value: 'Photography', label: 'Photography' },
    { value: 'Art', label: 'Art' },
    { value: 'Fashion', label: 'Fashion' },
    { value: 'Volunteering', label: 'Volunteering' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Outdoor Activities', label: 'Outdoor Activities' },
    { value: 'Dance', label: 'Dance' },
    { value: 'Social Media', label: 'Social Media' }
  ];
  const graduationClassOptions = [
    { value: '2024', label: '2024' },
    { value: '2025', label: '2025' },
    { value: '2026', label: '2026' },
    { value: '2027', label: '2027' },
    { value: '2028', label: '2028' }
  ];

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        fetchProfile(user.uid);
      } else {
        setLoading(false);
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchProfile = async (userId) => {
    const profileRef = doc(db, 'profiles', userId);
    const profileSnap = await getDoc(profileRef);

    if (profileSnap.exists()) {
      const profileData = profileSnap.data();
      setProfile(profileData);
      setUpdatedProfile({
        ...profileData,
        interests: profileData.interests.map(interest => ({ value: interest, label: interest })),
        graduationClass: { value: profileData.graduationClass, label: profileData.graduationClass }
      });
    } else {
      console.log('No such profile!');
    }
    setLoading(false);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setUpdatedProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleToggle = () => {
    setUpdatedProfile((prevState) => ({
      ...prevState,
      campusCrush: !prevState.campusCrush,
    }));
  };

  const handlePictureChange = (index, file) => {
    const updatedPictures = [...newPictures];
    const updatedFileNames = [...selectedFileNames];
    updatedPictures[index] = file;
    updatedFileNames[index] = file ? URL.createObjectURL(file) : null;
    setNewPictures(updatedPictures);
    setSelectedFileNames(updatedFileNames);
  };

  const handleSave = async () => {
    setSaving(true); // Start the loading screen
  
    if (auth.currentUser) {
      const userId = auth.currentUser.uid;
  
      const newPictureFiles = newPictures.filter((file) => file !== null);
  
      if (newPictureFiles.length > 0) {
        const existingPictures = profile.pictures;
        await Promise.all(
          existingPictures.map(async (url) => {
            const storageRef = ref(storage, url);
            try {
              await deleteObject(storageRef);
            } catch (error) {
              if (error.code === 'storage/object-not-found') {
                console.log('Picture does not exist in storage, skipping deletion');
              } else {
                console.error('Error deleting picture:', error);
              }
            }
          })
        );
  
        const pictureURLs = await Promise.all(
          newPictures.map(async (file, index) => {
            if (file) {
              const storageRef = ref(storage, `profiles/${userId}/picture_${index}`);
              await uploadBytes(storageRef, file);
              const url = await getDownloadURL(storageRef);
              return url;
            }
            return null;
          })
        );
  
        updatedProfile.pictures = pictureURLs.filter((url) => url !== null);
      } else {
        updatedProfile.pictures = profile.pictures;
      }
  
      const interestsArray = updatedProfile.interests.map(interest => interest.value);
      const graduationClassValue = updatedProfile.graduationClass.value;
  
      const cleanedProfile = Object.fromEntries(
        Object.entries({
          ...updatedProfile,
          interests: interestsArray,
          graduationClass: graduationClassValue
        }).filter(([_, v]) => v !== undefined && v !== null)
      );
  
      const profileRef = doc(db, 'profiles', userId);
      await updateDoc(profileRef, cleanedProfile);
  
      setProfile({
        ...profile,
        ...cleanedProfile,
      });
      setIsEditing(false);
    }
  
    setSaving(false); // End the loading screen
  };
  

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  if (loading) {
    return (
      <div className="loading-screen">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="profile-view">
      {saving && <div className="loading-overlay">Saving...</div>}
      {!profile ? (
        <p>No profile found.</p>
      ) : (
        <>
          <h1>Your Profile</h1>
          <Link to="/home">
            <button className="modern-button"><FaHome className="button-icon" />Home</button>
          </Link>
          {isEditing ? (
            <div className="profile-edit">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={updatedProfile.name}
                onChange={handleEditChange}
                placeholder="Name"
              />
              <label>Date of Birth</label>
              <input
                type="date"
                name="dob"
                value={updatedProfile.dob}
                onChange={handleEditChange}
                placeholder="Date of Birth"
              />
              <label>Major</label>
              <CustomSelect
                name="major"
                options={majors.map((major) => ({ value: major, label: major }))}
                value={{ value: updatedProfile.major, label: updatedProfile.major }}
                onChange={(selectedOption) => setUpdatedProfile({ ...updatedProfile, major: selectedOption.value })}
                required
              />
              <label>Ethnicity</label>
<CustomSelect
  name="ethnicity"
  options={ethnicities.map((ethnicity) => ({ value: ethnicity, label: ethnicity }))}
  value={{ value: updatedProfile.ethnicity, label: updatedProfile.ethnicity }}
  onChange={(selectedOption) => setUpdatedProfile({ ...updatedProfile, ethnicity: selectedOption.value })}
/>
              <label>Gender</label>
              <CustomSelect
                name="gender"
                options={[
                  { value: 'Man', label: 'Man' },
                  { value: 'Woman', label: 'Woman' },
                  { value: 'Non-binary', label: 'Non-binary' },
                ]}
                value={{ value: updatedProfile.gender, label: updatedProfile.gender }}
                onChange={(selectedOption) => setUpdatedProfile({ ...updatedProfile, gender: selectedOption.value })}
                required
              />
              <label>University</label>
              <CustomSelect
                name="university"
                options={universities.map((university) => ({ value: university, label: university }))}
                value={{ value: updatedProfile.university, label: updatedProfile.university }}
                onChange={(selectedOption) => setUpdatedProfile({ ...updatedProfile, university: selectedOption.value })}
                required
              />
              <label>Graduation Class</label>
              <CustomSelect
                name="graduationClass"
                options={graduationClassOptions}
                value={updatedProfile.graduationClass}
                onChange={(selectedOption) => setUpdatedProfile({ ...updatedProfile, graduationClass: selectedOption })}
                required
              />
              <label>Profile Bio</label>
              <textarea
                name="description"
                value={updatedProfile.description}
                onChange={handleEditChange}
                placeholder="Short Profile Bio"
              ></textarea>
              <label>Interests</label>
              <CustomSelect
                isMulti
                name="interests"
                options={interestOptions}
                value={updatedProfile.interests}
                onChange={(selectedOptions) => setUpdatedProfile({ ...updatedProfile, interests: selectedOptions })}
                required
              />
              <label>Appear on Campus Connect</label>
              
              <div className={`toggle-switch ${updatedProfile.campusCrush ? 'on' : 'off'}`} onClick={handleToggle}>
                <div className={`toggle-slider ${updatedProfile.campusCrush ? 'on' : 'off'}`}></div>
              </div>
              <label>Update Pictures</label>
              <div className="picture-inputs">
                {[0, 1, 2, 3, 4, 5].map((index) => (
                  <div key={index} className="file-input-wrapper">
                    <label htmlFor={`file-input-${index}`} className="file-input-label">
                      <div className="picture-preview">
                        {selectedFileNames[index] ? (
                          <img src={selectedFileNames[index]} alt={`Preview ${index}`} />
                        ) : (
                          <span>+</span>
                        )}
                      </div>
                    </label>
                    <input
                      id={`file-input-${index}`}
                      type="file"
                      className="custom-file-input"
                      onChange={(e) => handlePictureChange(index, e.target.files[0])}
                    />
                  </div>
                ))}
              </div>
              <button className="modern-button" onClick={handleSave}>Save</button>
            </div>
          ) : (
            <div className="profile-details">
              <h2>{profile.name}</h2>
              <div className="profile-pictures">
                {profile.pictures && profile.pictures.map((url, index) => (
                  <img key={index} src={url} alt={`Profile ${index + 1}`} className="profile-picture" />
                ))}
              </div>
              <p>Age: {calculateAge(profile.dob)}</p>
              <p>Major: {profile.major}</p>
              <p>Ethnicity: {profile.ethnicity}</p>
              <p>Gender: {profile.gender}</p>
              <p>University: {profile.university}</p>
              <p>Graduation Class: {profile.graduationClass}</p>
              <p>Profile Bio: {profile.description}</p>
              <p>Interests: {profile.interests.join(', ')}</p>
              <p>Appear on Campus Crush: {profile.campusCrush ? 'Yes' : 'No'}</p>
              <button className="modern-button" onClick={() => {
                setIsEditing(true);
                setNewPictures([null, null, null, null, null, null]);
                setSelectedFileNames([null, null, null, null, null, null]);
              }}>Edit Profile</button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProfileView;
