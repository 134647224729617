// src/components/ProtectedRoute.js
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { db, auth } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

const ProtectedRoute = ({ children }) => {
  const [isAllowed, setIsAllowed] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAccess = async () => {
      const user = auth.currentUser;
      if (user) {
        const profileRef = doc(db, 'profiles', user.uid);
        const profileSnap = await getDoc(profileRef);
        if (profileSnap.exists()) {
          const profileData = profileSnap.data();
          if (profileData.campusCrush) {
            setIsAllowed(true);
          } else {
            setIsAllowed(false);
          }
        } else {
          setIsAllowed(false);
        }
      } else {
        setIsAllowed(false);
      }
      setLoading(false);
    };

    checkAccess();
  }, []);

  useEffect(() => {
    if (isAllowed === false) {
      toast.error('Access denied. Check again if Campus Connect is enabled.');
    }
  }, [isAllowed]);

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator while checking access
  }

  return isAllowed ? children : <Navigate to="/home" />;
};

export default ProtectedRoute;
