import React from 'react';
import './Features.css'; // Import the CSS for Features
import campusCrazeImage from '../images/campuscrazefeature.jpg'; // Import the Campus Craze image
import campusCrushImage from '../images/campusconnectfeature.png'; // Import the Campus Crush image

const Features = () => {
  return (
    <div id="features" className="features-container"> {/* Added ID here */}
      <h2 className="features-heading">Features</h2>
      <div className="features">
        <div className="feature">
          <h3 className="feature-title">Campus Craze</h3>
          <img src={campusCrazeImage} alt="Campus Craze" className="feature-image" />
          <p className="feature-description">
          Discover and attend diverse events across your campus! Or be the host of your own epic event!
          </p>
        </div>
        <div className="feature">
          <h3 className="feature-title">Campus Connect</h3>
          <img src={campusCrushImage} alt="Campus Crush" className="feature-image" />
          <p className="feature-description">
          Think someone is cool? Don’t be shy and send them a DM! 
          </p>
        </div>
      </div>
    </div>
  );
};

export default Features;
