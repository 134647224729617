import React, { useState, useEffect, useRef, useCallback } from 'react';
import { db, auth } from '../firebaseConfig';
import { collection, addDoc, query, orderBy, onSnapshot, serverTimestamp, doc, getDoc, limit, startAfter, getDocs } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import ProfileCard from '../components/ProfileCard';
import './Chat.css';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [otherProfile, setOtherProfile] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const { chatId } = useParams();
  const messagesEndRef = useRef(null);
  const messagesTopRef = useRef(null);
  const navigate = useNavigate();
  const [lastVisible, setLastVisible] = useState(null);

  const fetchOtherProfile = useCallback(async () => {
    if (otherProfile) return; // Avoid fetching if already cached
    const chatDoc = await getDoc(doc(db, 'chats', chatId));
    if (chatDoc.exists()) {
      const participants = chatDoc.data().participants.filter(id => id !== auth.currentUser.uid);
      if (participants.length > 0) {
        const otherProfileDoc = await getDoc(doc(db, 'profiles', participants[0]));
        if (otherProfileDoc.exists()) {
          setOtherProfile(otherProfileDoc.data());
        }
      }
    }
  }, [chatId, otherProfile]);

  useEffect(() => {
    if (!chatId) return;

    const messagesQuery = query(
      collection(db, 'chats', chatId, 'messages'),
      orderBy('timestamp', 'asc'),
      limit(50) // Fetch the last 50 messages
    );

    const unsubscribe = onSnapshot(messagesQuery, (querySnapshot) => {
      const messagesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMessages(messagesList);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      scrollToBottom();
    });

    fetchOtherProfile();

    return () => unsubscribe();
  }, [chatId, fetchOtherProfile]);

  const loadMoreMessages = async () => {
    if (!lastVisible) return;

    const messagesQuery = query(
      collection(db, 'chats', chatId, 'messages'),
      orderBy('timestamp', 'asc'),
      startAfter(lastVisible),
      limit(50) // Fetch the next 50 messages
    );

    const querySnapshot = await getDocs(messagesQuery);
    const moreMessages = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    setMessages(prevMessages => [...moreMessages, ...prevMessages]);
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (messagesTopRef.current && messagesTopRef.current.getBoundingClientRect().top >= 0) {
        loadMoreMessages();
      }
    };

    const chatBox = document.querySelector('.chat-box');
    chatBox.addEventListener('scroll', handleScroll);

    return () => {
      chatBox.removeEventListener('scroll', handleScroll);
    };
  }, [lastVisible]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    const userId = auth.currentUser.uid;

    await addDoc(collection(db, 'chats', chatId, 'messages'), {
      senderId: userId,
      message: newMessage,
      timestamp: serverTimestamp(),
    });

    setNewMessage('');
    scrollToBottom();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevents adding a new line in the input
      handleSendMessage();
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="chat-container">
      {showProfile ? (
        <div className="profile-card-fullscreen">
          <button className="close-profile-button" onClick={() => setShowProfile(false)}>Close</button>
          {otherProfile && <ProfileCard profile={otherProfile} />}
        </div>
      ) : (
        <>
          <div className="chat-content">
            <div className="chat-header">
              <button className="back-arrow" onClick={() => navigate('/inbox')}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="back-arrow-icon"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
              </button>
              {otherProfile && (
                <>
                  <img src={otherProfile.pictures[0]} alt="Profile" className="header-profile-pic" />
                  <span className="header-profile-name">{otherProfile.name}</span>
                  <button className="view-profile-button" onClick={() => setShowProfile(true)}>View Profile</button>
                </>
              )}
            </div>
            <div className="chat-box">
              <div ref={messagesTopRef} />
              {messages.map(msg => (
                <div key={msg.id} className={`message ${msg.senderId === auth.currentUser.uid ? 'sent' : 'received'}`}>
                  {msg.senderId !== auth.currentUser.uid && otherProfile && (
                    <img src={otherProfile.pictures[0]} alt="Profile" className="message-profile-pic" />
                  )}
                  <div className="message-content">
                    <p>{msg.message}</p>
                    {msg.timestamp ? (
                      <span className="message-timestamp">
                        {new Date(msg.timestamp.seconds * 1000).toLocaleDateString()} {new Date(msg.timestamp.seconds * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </span>
                    ) : (
                      <span className="message-timestamp">Sending...</span>
                    )}
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
            <div className="input-box">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={handleKeyDown} // Detect Enter key
                placeholder="Type a message"
              />
              <button type="button" onClick={handleSendMessage}>Send</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Chat;
