// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAmnpI3padRoqTCTSraLgIISi3J9Ix4P8E",
  authDomain: "campuscraze-2dc3b.firebaseapp.com",
  projectId: "campuscraze-2dc3b",
  storageBucket: "campuscraze-2dc3b.appspot.com",
  messagingSenderId: "196951787188",
  appId: "1:196951787188:web:2581f4bf62a36b4276b9e1",
  measurementId: "G-4D2CKCWXL9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

// Set up Google Auth Provider
const provider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  const result = await signInWithPopup(auth, provider);
  const user = result.user;
  
  const userDoc = doc(db, "users", user.uid);
  const docSnap = await getDoc(userDoc);
  
  if (!docSnap.exists()) {
    await setDoc(userDoc, { email: user.email });
    return false; // No profile exists
  }
  
  return true; // Profile exists
};

export { db, auth, signInWithGoogle };
