import React, { useState } from 'react';
import './FAQ.css'; // Import the CSS for FAQ

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is the CampusCraze feature?",
      answer: "CampusCraze is your ultimate platform to discover and attend the best university events. Our mission is to enhance your university experience by connecting you with fellow students and creating unforgettable memories."
    },
    {
      question: "What is the CampusConnect feature?",
      answer: "CampusConnect is an exclusive student connection platform where you can discover students in the same major, graduation class, or with shared hobbies and interests. DM new friends or your crush – skip the swipe and just vibe!"
    },
    {
      question: "How do I join events?",
      answer: "Simply sign up on CampusCraze, browse the listed events, and RSVP to the ones you're interested in. You'll get all the details you need to attend and have a great time!"
    },
    {
      question: "Is CampusCraze free to use?",
      answer: "Yes, CampusCraze is free to use! You can discover events, connect with fellow students, and join events without any cost. Some events might have their own entry fees, which will be mentioned in the event details."
    }
  ];

  return (
    <div id="faq" className="faq-container"> {/* Added ID here */}
      <h2 className="faq-heading">FAQs</h2>
      <div className="faq-list">
        {faqData.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              {faq.question}
              <span className="faq-icon">{openIndex === index ? '-' : '+'}</span>
            </div>
            {openIndex === index && <div className="faq-answer">{faq.answer}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
