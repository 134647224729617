import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ProfileForm from './components/ProfileForm';
import ProfileView from './pages/ProfileView';
import InitialScreen from './pages/InitialScreen';
import CampusCraze from './pages/CampusCraze';
import CampusCrush from './pages/CampusCrush';
import PartyForm from './components/PartyForm';
import ProfileSwipe from './components/ProfileSwipe';
import Inbox from './components/Inbox';
import Chat from './pages/Chat';
import ProtectedRoute from './components/ProtectedRoute';
import LandingPage from './pages/LandingPage';
import Membership from './pages/Membership';
import Success from './pages/Success';
import Cancel from './pages/Cancel';
import CancellationConfirmation from './pages/CancellationConfirmation';
import Analytics from './components/Analytics';  // Import the Analytics component

function App() {
  return (
    <Router>
      <Analytics />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/profile" element={<ProfileForm />} />
        <Route path="/view-profile" element={<ProfileView />} />
        <Route path="/campuscraze" element={<CampusCraze />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/success" element={<Success />} />
        <Route path="/cancel" element={<Cancel />} />
        <Route path="/cancellation-confirmation" element={<CancellationConfirmation />} />
      
        <Route
          path="/campusconnect"
          element={
            <ProtectedRoute>
              <CampusCrush />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile-swipe"
          element={
            <ProtectedRoute>
              <ProfileSwipe />
            </ProtectedRoute>
          }
        />
        <Route
          path="/inbox"
          element={
            <ProtectedRoute>
              <Inbox />
            </ProtectedRoute>
          }
        />
        <Route path="/chat/:chatId" element={<Chat />} />
        <Route path="/add-party" element={<PartyForm />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
