import React, { useState } from 'react';
import { db, auth } from '../firebaseConfig';
import { collection, addDoc, getDoc, doc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import './PartyForm.css';
import { FaArrowLeft } from 'react-icons/fa';

const universities = [
  "University of Toronto",
  "University of British Columbia",
  "McGill University",
  "University of Alberta",
  "University of Calgary",
  "University of Waterloo",
  "Western University",
  "Queen's University",
  "McMaster University",
  "University of Ottawa",
  "Simon Fraser University",
  "Dalhousie University",
  "University of Montreal",
  "Université Laval",
  "University of Manitoba",
  "University of Saskatchewan",
  "University of Victoria",
  "York University",
  "University of Guelph",
  "Western University"
];


const PartyForm = () => {
  const [partyName, setPartyName] = useState('');
  const [partyDate, setPartyDate] = useState('');
  const [partyTime, setPartyTime] = useState('');
  const [partyAddress, setPartyAddress] = useState('');
  const [partyDescription, setPartyDescription] = useState('');
  const [university, setUniversity] = useState('');
  const [pictureFile, setPictureFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(''); // Error state
  const navigate = useNavigate();
  const storage = getStorage();

  const handlePictureChange = (file) => {
    setPictureFile(file);
    setSelectedFileName(file ? file.name : '');
    setError(''); // Clear error message on file change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!pictureFile) {
      setError('Please upload a picture.');
      setLoading(false);
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      console.error('User is not authenticated');
      navigate('/login');
      return;
    }

    const userId = user.uid;
    const userDoc = await getDoc(doc(db, 'profiles', userId));
    const createdByMembership = userDoc.exists() ? userDoc.data().membership || 'Free' : 'Free';

    try {
      const partyDocRef = await addDoc(collection(db, 'parties'), {
        name: partyName,
        date: partyDate, // Store date as string
        time: partyTime, // Store time as string
        address: partyAddress,
        description: partyDescription,
        university,
        userId,
        picture: '', // Placeholder for picture URL
        createdByMembership,
        rsvps: [], // Initialize RSVPs array
      });

      let pictureURL = '';
      if (pictureFile) {
        const storageRef = ref(storage, `parties/${partyDocRef.id}/picture`);
        await uploadBytes(storageRef, pictureFile);
        pictureURL = await getDownloadURL(storageRef);

        // Update the party document with the picture URL
        await updateDoc(partyDocRef, {
          picture: pictureURL,
        });
      }

      navigate('/campuscraze'); // Navigate back to CampusCraze after submission
    } catch (error) {
      console.error('Error adding party: ', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="party-form-container">
      {loading && <div className="loading-overlay">Loading...</div>}
      <div className="party-form">
        <h1>Add Your Event</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={partyName}
            onChange={(e) => setPartyName(e.target.value)}
            placeholder="Event Name"
            required
          />
          <label htmlFor="party-date">Event Date</label>
          <input
            id="party-date"
            type="date"
            value={partyDate}
            onChange={(e) => setPartyDate(e.target.value)}
            required
          />
          <label htmlFor="party-time">Event Time</label>
          <input
            id="party-time"
            type="time"
            value={partyTime}
            onChange={(e) => setPartyTime(e.target.value)}
            required
          />
          <input
            type="text"
            value={partyAddress}
            onChange={(e) => setPartyAddress(e.target.value)}
            placeholder="Event Address"
            required
          />
          <select 
            value={university} 
            onChange={(e) => setUniversity(e.target.value)} 
            required
            className="university-select"
          >
            <option value="" disabled>Select University</option>
            {universities.map((uni, index) => (
              <option key={index} value={uni}>{uni}</option>
            ))}
          </select>
          <textarea
            value={partyDescription}
            onChange={(e) => setPartyDescription(e.target.value)}
            placeholder="Event Description"
            required
          />
          <div className="file-input-container">
            {error && <div className="error-message">{error}</div>} {/* Display error message */}
            <label htmlFor="file-input" className="file-input-label">
              {selectedFileName || 'Insert Event Picture'}
            </label>
            <input
              type="file"
              id="file-input"
              className="custom-file-input"
              name="partyPicture"
              onChange={(e) => handlePictureChange(e.target.files[0])}
            />
          </div>
          <button type="submit">Add Event</button>
        </form>
      </div>
      <div className="button-container">
        <button className="back-button-partyform" onClick={() => navigate('/campuscraze')}>
          <FaArrowLeft className="button-icon" /> Back
        </button>
      </div>
    </div>
  );
};

export default PartyForm;
