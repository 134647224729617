import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { collection, query, getDocs, updateDoc, doc, arrayUnion, arrayRemove, deleteDoc, getDoc } from 'firebase/firestore';
import PartyCard from '../components/PartyCard';
import { Link, useNavigate } from 'react-router-dom';
import './CampusCraze.css';
import campusCrazeLogo from '../images/campuscraze.png'; // Add your logo image
import { FaHome, FaPlus } from 'react-icons/fa';
import dayjs from 'dayjs';

const universities = [
  "University of Toronto",
  "University of British Columbia",
  "McGill University",
  "University of Alberta",
  "University of Calgary",
  "University of Waterloo",
  "Western University",
  "Queen's University",
  "McMaster University",
  "University of Ottawa",
  "Simon Fraser University",
  "Dalhousie University",
  "University of Montreal",
  "Université Laval",
  "University of Manitoba",
  "University of Saskatchewan",
  "University of Victoria",
  "York University",
  "University of Guelph",
  "Western University",
  "Toronto Metropolitan University"
];

const CampusCraze = () => {
  const [parties, setParties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [membership, setMembership] = useState('Free');
  const [filterUniversity, setFilterUniversity] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [showUserParties, setShowUserParties] = useState(false);
  const [showFavorites, setShowFavorites] = useState(false);
  const [showRSVPedParties, setShowRSVPedParties] = useState(false); // New state for RSVP'ed parties
  const [uniqueDates, setUniqueDates] = useState([]);
  const [upcomingParties, setUpcomingParties] = useState([]);
  const [pastParties, setPastParties] = useState([]);
  const [viewMode, setViewMode] = useState('upcoming'); // 'upcoming' or 'past'
  const navigate = useNavigate();

  useEffect(() => {
    const fetchParties = async () => {
      setLoading(true);
      try {
        const q = query(collection(db, 'parties'));
        const querySnapshot = await getDocs(q);
        const partiesList = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          if (data.date && data.date.toDate) {
            data.date = data.date.toDate().toLocaleDateString('en-CA');
          }
          return {
            id: doc.id,
            ...data,
            rsvps: data.rsvps || [],
            likes: data.likes || 0,
            likedBy: data.likedBy || [],
            createdByMembership: data.createdByMembership || 'Free',
          };
        });
        setParties(partiesList);
      } catch (error) {
        console.error('Error fetching parties: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchParties();
  }, []);

  useEffect(() => {
    const fetchMembershipStatus = async (userId) => {
      const userDoc = await getDoc(doc(db, 'profiles', userId));
      if (userDoc.exists()) {
        setMembership(userDoc.data().membership || 'Free');
      } else {
        setMembership('Free');
      }
    };

    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUserId(user.uid);
        fetchMembershipStatus(user.uid);
      } else {
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleRSVP = async (partyId) => {
    try {
      const partyRef = doc(db, 'parties', partyId);
      await updateDoc(partyRef, {
        rsvps: arrayUnion(userId),
      });
      setParties((prevParties) =>
        prevParties.map((party) =>
          party.id === partyId ? { ...party, rsvps: [...party.rsvps, userId] } : party
        )
      );
    } catch (error) {
      console.error('Error RSVPing: ', error);
    }
  };

  const handleCancelRSVP = async (partyId) => {
    try {
      const partyRef = doc(db, 'parties', partyId);
      await updateDoc(partyRef, {
        rsvps: arrayRemove(userId),
      });
      setParties((prevParties) =>
        prevParties.map((party) =>
          party.id === partyId ? { ...party, rsvps: party.rsvps.filter(id => id !== userId) } : party
        )
      );
    } catch (error) {
      console.error('Error cancelling RSVP: ', error);
    }
  };

  const handleDelete = async (partyId) => {
    try {
      const partyRef = doc(db, 'parties', partyId);
      await deleteDoc(partyRef);
      setParties((prevParties) => {
        const updatedParties = prevParties.filter((party) => party.id !== partyId);
        const hasPartiesForSelectedDate = updatedParties.some(party => party.date === filterDate);
        if (!hasPartiesForSelectedDate) {
          setFilterDate('');
        }
        return updatedParties;
      });
    } catch (error) {
      console.error('Error deleting party: ', error);
    }
  };

  const handleLike = async (partyId) => {
    try {
      const partyRef = doc(db, 'parties', partyId);
      const partyDoc = await getDoc(partyRef);
      const partyData = partyDoc.data();
      const newLikes = partyData.likedBy.includes(userId) ? arrayRemove(userId) : arrayUnion(userId);

      await updateDoc(partyRef, {
        likedBy: newLikes,
        likes: partyData.likedBy.includes(userId) ? partyData.likes - 1 : partyData.likes + 1,
      });

      setParties((prevParties) =>
        prevParties.map((party) =>
          party.id === partyId
            ? {
                ...party,
                likedBy: partyData.likedBy.includes(userId)
                  ? party.likedBy.filter(id => id !== userId)
                  : [...party.likedBy, userId],
                likes: partyData.likedBy.includes(userId) ? party.likes - 1 : party.likes + 1,
              }
            : party
        )
      );
    } catch (error) {
      console.error('Error liking party: ', error);
    }
  };

  useEffect(() => {
    if (showFavorites || showUserParties || showRSVPedParties) { // Include showRSVPedParties in the dependency array
      setLoading(true);
      const fetchParties = async () => {
        try {
          const q = query(collection(db, 'parties'));
          const querySnapshot = await getDocs(q);
          const partiesList = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            if (data.date && data.date.toDate) {
              data.date = data.date.toDate().toLocaleDateString('en-CA');
            }
            return {
              id: doc.id,
              ...data,
              rsvps: data.rsvps || [],
              likes: data.likes || 0,
              likedBy: data.likedBy || [],
              createdByMembership: data.createdByMembership || 'Free',
            };
          });
          setParties(partiesList);
        } catch (error) {
          console.error('Error fetching events: ', error);
        } finally {
          setLoading(false);
        }
      };

      fetchParties();
    }
  }, [showFavorites, showUserParties, showRSVPedParties]); // Add showRSVPedParties here

  useEffect(() => {
    const uniqueDatesSet = new Set(parties.map(party => party.date));
    setUniqueDates([...uniqueDatesSet].sort());
  }, [parties]);

  useEffect(() => {
    const now = dayjs().startOf('day'); // Start of the current day

    const upcoming = parties.filter(party => dayjs(party.date).isSame(now, 'day') || dayjs(party.date).isAfter(now));
    const past = parties.filter(party => dayjs(party.date).isBefore(now, 'day'));

    setUpcomingParties(upcoming);
    setPastParties(past);
  }, [parties]);

  const sortedParties = parties.sort((a, b) => {
    if (a.createdByMembership === 'Elite' && b.createdByMembership !== 'Elite') return -1;
    if (a.createdByMembership !== 'Elite' && b.createdByMembership === 'Elite') return 1;
    if (a.createdByMembership === 'Elite' && b.createdByMembership === 'Elite') return b.likes - a.likes;
    if (a.createdByMembership === 'Free' && b.createdByMembership === 'Free') return b.likes - a.likes;
    return 0;
  });

  const filteredUpcomingParties = upcomingParties.filter(party => {
    const matchesUniversity = !filterUniversity || party.university === filterUniversity;
    const matchesDate = !filterDate || party.date === filterDate;
    const matchesUserParties = showUserParties && party.userId === userId;
    const matchesFavorites = showFavorites && party.likedBy.includes(userId);
    const matchesRSVPedParties = showRSVPedParties && party.rsvps.includes(userId);
    return matchesUniversity && matchesDate && (matchesUserParties || matchesFavorites || matchesRSVPedParties || (!showUserParties && !showFavorites && !showRSVPedParties));
  });

  const filteredPastParties = pastParties.filter(party => {
    const matchesUniversity = !filterUniversity || party.university === filterUniversity;
    const matchesDate = !filterDate || party.date === filterDate;
    const matchesUserParties = showUserParties && party.userId === userId;
    const matchesFavorites = showFavorites && party.likedBy.includes(userId);
    const matchesRSVPedParties = showRSVPedParties && party.rsvps.includes(userId);
    return matchesUniversity && matchesDate && (matchesUserParties || matchesFavorites || matchesRSVPedParties || (!showUserParties && !showFavorites && !showRSVPedParties));
  });

  const handleShowUserParties = () => {
    setShowUserParties(!showUserParties);
    if (!showUserParties) {
      setShowFavorites(false);
      setShowRSVPedParties(false);
      setViewMode('upcoming');
    }
  };

  const handleShowFavorites = () => {
    setShowFavorites(!showFavorites);
    if (!showFavorites) {
      setShowUserParties(false);
      setShowRSVPedParties(false);
      setViewMode('upcoming');
    }
  };

  const handleShowRSVPedParties = () => { // New handler for RSVP'ed parties
    setShowRSVPedParties(!showRSVPedParties);
    if (!showRSVPedParties) {
      setShowUserParties(false);
      setShowFavorites(false);
      setViewMode('upcoming');
    }
  };

  const handleViewMode = () => {
    setViewMode(viewMode === 'upcoming' ? 'past' : 'upcoming');
    if (viewMode === 'upcoming') {
      setShowUserParties(false);
      setShowFavorites(false);
      setShowRSVPedParties(false);
    }
  };

  return (
    <div className="campus-craze">
      <img src={campusCrazeLogo} alt="Campus Craze Logo" className="logo" />
      <div className="buttons-container">
        <Link to="/home">
          <button className="modern-button"><FaHome className="button-icon" />Home</button>
        </Link>
        <Link to="/add-party">
          <button className="modern-button"><FaPlus className="button-icon" />Add Party / Event</button>
        </Link>
        <button
          className={`modern-button ${showUserParties ? 'active' : ''}`}
          onClick={handleShowUserParties}
        >
          {showUserParties ? 'Show All Events' : 'My Events'}
        </button>
        <button
          className={`modern-button ${showFavorites ? 'active' : ''}`}
          onClick={handleShowFavorites}
        >
          {showFavorites ? 'Show All Events' : 'Favorited'}
        </button>
        <button
          className={`modern-button ${showRSVPedParties ? 'active' : ''}`} // New button for RSVP'ed parties
          onClick={handleShowRSVPedParties}
        >
          {showRSVPedParties ? 'Show All Events' : 'RSVP\'ED'}

        </button>
        <button
          className="modern-button"
          onClick={handleViewMode}
        >
          {viewMode === 'upcoming' ? 'View Past Events' : 'View Upcoming Events'}
        </button>
      </div>
      <div className="filter-container">
        <select value={filterUniversity} onChange={(e) => setFilterUniversity(e.target.value)}>
          <option value="">All Universities</option>
          {universities.map((uni, index) => (
            <option key={index} value={uni}>{uni}</option>
          ))}
        </select>
        <select value={filterDate} onChange={(e) => setFilterDate(e.target.value)}>
          <option value="">All Dates</option>
          {uniqueDates.map((date, index) => (
            <option key={index} value={date}>{date}</option>
          ))}
        </select>
      </div>
      <div className="parties-container">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {viewMode === 'upcoming' && filteredUpcomingParties.length > 0 && (
              <div className="upcoming-parties">
                <h3>{showFavorites ? 'Your Favorites' : (showUserParties ? 'Your Events' : (showRSVPedParties ? 'Your RSVP\'ed Events' : 'Upcoming Events'))}</h3>
                <div className="parties-grid">
                  {filteredUpcomingParties.map((party, index) => (
                    <PartyCard
                      key={index}
                      party={party}
                      onRSVP={handleRSVP}
                      onCancelRSVP={handleCancelRSVP}
                      onDelete={handleDelete}
                      onLike={handleLike}
                      userHasRSVPd={party.rsvps.includes(userId)}
                      isOwner={party.userId === userId}
                    />
                  ))}
                </div>
              </div>
            )}
            {viewMode === 'past' && filteredPastParties.length > 0 && (
              <div className="past-parties">
                <h3>Past Events</h3>
                <div className="parties-grid">
                  {filteredPastParties.map((party, index) => (
                    <PartyCard
                      key={index}
                      party={party}
                      onRSVP={handleRSVP}
                      onCancelRSVP={handleCancelRSVP}
                      onDelete={handleDelete}
                      onLike={handleLike}
                      userHasRSVPd={party.rsvps.includes(userId)}
                      isOwner={party.userId === userId}
                    />
                  ))}
                </div>
              </div>
            )}
            {viewMode === 'upcoming' && filteredUpcomingParties.length === 0 && (
              <p>No events available.</p>
            )}
            {viewMode === 'past' && filteredPastParties.length === 0 && (
              <p>No events available.</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CampusCraze;
